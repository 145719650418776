import {Component} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {SignInPage} from '../../pages/sign-in/sign-in.page';
import {Router} from '@angular/router';
import {SignUpPage} from '../../pages/sign-up/sign-up.page';
import {PopoverController} from '@ionic/angular';
import {HomePage} from '../../pages/home/home.page';
import {MyOrdersPage} from '../../pages/my-orders/my-orders.page';
import {AccountPage} from '../../pages/account/account.page';
import {PrivacyPage} from '../../pages/privacy/privacy.page';
import {TosPage} from '../../pages/tos/tos.page';
import {ImpressumPage} from '../../pages/impressum/impressum.page';
import {MatSnackBar} from '@angular/material/snack-bar';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}
@Component({
	selector: 'app-menu-popover',
	templateUrl: './menu-popover.component.html',
	styleUrls: ['menu-popover.component.scss']
})
export class MenuPopoverComponent extends RepositoryDirective {
	environment = environment;
	languageEnum = Language;
	constructor(
		public repository: RepositoryService,
		private router: Router,
		private popoverCtrl: PopoverController,
		private snackbarCtrl: MatSnackBar,
		public translate: TranslateService
	) {
		super(repository);
		console.log(this.translate.currentLang);
	}

	async signIn() {
		await this.popoverCtrl.dismiss();
		await SignInPage.navigate(this.router);
	}

	async signUp() {
		await this.popoverCtrl.dismiss();
		SignUpPage.navigate(this.router);
	}

	async mySpace() {
		await this.popoverCtrl.dismiss();
		await AccountPage.navigate(this.router);
	}

	async myOrders() {
		await this.popoverCtrl.dismiss();
		MyOrdersPage.navigate(this.router);
	}

	async logout() {
		try {
			this.repository.customerAuth.emit(null);
			this.snackbarCtrl.open(
				this.translate.instant('menu_popover.logout_success'),
				null,
				{
					duration: 2000
				}
			);
		} catch (e) {
			this.snackbarCtrl.open(
				this.translate.instant('menu_popover.logout_error'),
				null,
				{
					duration: 2000
				}
			);
		}
		await this.popoverCtrl.dismiss();
	}

	async home() {
		await this.popoverCtrl.dismiss();
		await HomePage.navigate(this.router);
	}

	async privacy() {
		await this.popoverCtrl.dismiss();
		await PrivacyPage.navigate(this.router);
	}

	async tos() {
		await this.popoverCtrl.dismiss();
		await TosPage.navigate(this.router);
	}

	async impressum() {
		await this.popoverCtrl.dismiss();
		ImpressumPage.navigate(this.router);
	}

	dismiss() {
		this.popoverCtrl.dismiss();
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
}
