import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {TranslateService} from '@ngx-translate/core';
import {
	AlertController,
	ModalController,
	PickerController
} from '@ionic/angular';
import validator from 'validator';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MenuPage} from '../menu/menu.page';
import Customer from '../../../smoothr-web-app-core/models/Customer';
import {Gender} from '../../../smoothr-web-app-core/enums/Gender';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {
	phoneCountries,
	phoneCountryComparator
} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-account',
	templateUrl: './account.page.html',
	styleUrls: ['account.page.scss']
})
export class AccountPage extends RepositoryDirective {
	static url = 'account';

	customer: Customer;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover'
	};
	loading = false;

	gender = Gender;
	phoneCountries = phoneCountries;
	phoneCountryComparator = phoneCountryComparator;

	constructor(
		public repository: RepositoryService,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService,
		private pickerCtrl: PickerController,
		private modalCtrl: ModalController,
		private alertCtrl: AlertController
	) {
		super(repository);
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(AccountPage.url);
	}

	onCustomer() {
		super.onCustomer();
		if (this.repository.customer && this.repository.customer.isAnonymous) {
			HomePage.navigate(this.router);
		} else {
			this.customer = this.repository.customer;
		}
	}

	async saveCustomer() {
		if (!this.customer) {
			this.snackbarCtrl.open(
				this.translate.instant('account.please_wait'),
				null,
				{
					duration: 2000
				}
			);
			return;
		}
		if (!(await this.validate())) {
			return;
		}
		this.loading = true;
		try {
			const response = await Api.patchCustomer(this.customer);
			this.snackbarCtrl.open(this.translate.instant('account.success'), null, {
				duration: 2000
			});
			this.customer = response.data;
			this.repository._customerAuth.customer = this.customer;
			this.repository.customerAuth.emit(this.repository._customerAuth);
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('account.error'), null, {
				duration: 2000
			});
		}
		this.loading = false;
	}

	async home() {
		await HomePage.navigate(this.router);
	}

	async validate(): Promise<boolean> {
		if (!this.customer.name || this.customer.name.length < 3) {
			this.snackbarCtrl.open(this.translate.instant('errors.name'), null, {
				duration: 2000
			});
			return false;
		}
		if (!this.customer.email || !validator.isEmail(this.customer.email)) {
			this.snackbarCtrl.open(this.translate.instant('errors.email'), null, {
				duration: 2000
			});
			return false;
		}
		if (
			!ValidationUtils.validatePhone(
				this.customer.phoneCountry,
				this.customer.phone
			)
		) {
			this.snackbarCtrl.open(this.translate.instant('errors.phone'), null, {
				duration: 2000
			});
			return false;
		}
		if (!this.customer.street || this.customer.street.length < 1) {
			this.snackbarCtrl.open(this.translate.instant('errors.street'), null, {
				duration: 2000
			});
			return false;
		}
		if (!this.customer.number || this.customer.number.length < 1) {
			this.snackbarCtrl.open(
				this.translate.instant('errors.street_number'),
				null,
				{
					duration: 2000
				}
			);
			return false;
		}
		if (!this.customer.postalCode || this.customer.postalCode.length < 1) {
			this.snackbarCtrl.open(this.translate.instant('errors.postal'), null, {
				duration: 2000
			});
			return false;
		}
		if (!this.customer.city || this.customer.city.length < 1) {
			this.snackbarCtrl.open(this.translate.instant('errors.city'), null, {
				duration: 2000
			});
			return false;
		}
		if (environment.countryList.indexOf(this.customer.country) < 0) {
			this.snackbarCtrl.open(this.translate.instant('errors.country'), null, {
				duration: 2000
			});
			return false;
		}
		return true;
	}

	async deleteAccount() {
		const alert = await this.alertCtrl.create({
			header: this.translate.instant('account.delete_account.header'),
			message: this.translate.instant('account.delete_account.msg'),
			buttons: [
				{
					text: this.translate.instant('account.delete_account.no')
				},
				{
					text: this.translate.instant('account.delete_account.yes'),
					handler: async () => {
						this.loading = true;
						try {
							await Api.deleteAccount();
							this.snackbarCtrl.open(
								this.translate.instant('account.delete_account.success'),
								null,
								{
									duration: 2000
								}
							);
							this.repository.customerAuth.emit(null);
							await HomePage.navigate(this.router);
						} catch (e) {
							this.loading = false;
							this.snackbarCtrl.open(
								this.translate.instant('account.delete_account.error'),
								null,
								{
									duration: 2000
								}
							);
						}
					}
				}
			]
		});
		await alert.present();
		await alert.onDidDismiss();
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
