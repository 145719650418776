import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {PickerController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {currencySymbols} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-tip',
	templateUrl: './tip.component.html',
	styleUrls: ['tip.component.scss']
})
export class TipComponent implements OnInit {
	@Output()
	tip = new EventEmitter<number>();
	percents = [5, 10, 15];
	numberToCurrency = numberToCurrency;

	constructor(
		private pickerCtrl: PickerController,
		private translate: TranslateService
	) {}

	private _totalPrice: number;

	get totalPrice(): number {
		return this._totalPrice;
	}

	set totalPrice(value: number) {
		if (this._totalPrice === value) {
			return;
		}
		this._totalPrice = value;
		this.recalculateAndEmitTip();
	}

	private _order: Order;

	get order(): Order {
		return this._order;
	}

	@Input()
	set order(order: Order) {
		this._order = order;
		this.totalPrice = OrderUtils.orderTotalPrice(order, true, true);
	}

	get currency(): string {
		return this.order && this.order.currency;
	}

	private _customTip: number;

	get customTip(): number {
		return this._customTip;
	}

	set customTip(value: number) {
		this._customTip = value;
		this.recalculateAndEmitTip();
	}

	private _selectedPercent: number = null;

	get selectedPercent(): number {
		return this._selectedPercent;
	}

	set selectedPercent(percent: number) {
		this._selectedPercent = percent;
		this.recalculateAndEmitTip();
	}

	ngOnInit() {}

	async showCustomPicker(): Promise<number> {
		const zeroToHundred = [];
		for (let i = 0; i <= 100; i++) {
			zeroToHundred.push(i);
		}
		const tenSteps = [];
		for (let i = 0; i < 100; i = i + 5) {
			tenSteps.push(i);
		}
		const picker = await this.pickerCtrl.create({
			cssClass: 'tip-picker',
			animated: false,
			columns: [
				{
					name: 'euro',
					options: zeroToHundred.map(euro => {
						return {
							text: euro,
							value: euro
						};
					})
				},
				{
					cssClass: 'comma-column',
					name: 'comma',
					options: [
						{
							text: ','
						}
					]
				},
				{
					name: 'cent',
					options: tenSteps.map(cent => {
						return {
							text: cent < 10 ? `0${cent}` : cent,
							value: cent
						};
					})
				},
				{
					cssClass: 'sign-column',
					name: 'sign',
					options: [
						{
							text: currencySymbols[this.currency.toLowerCase()]
						}
					]
				}
			],
			buttons: [
				{
					text: this.translate.instant('tip_component.custom_picker.cancel'),
					role: 'cancel'
				},
				{
					text: this.translate.instant('tip_component.custom_picker.confirm')
				}
			]
		});
		await picker.present();
		const result = (await picker.onDidDismiss()).data;
		if (!result) {
			return -1;
		}
		const selectedValue = +result.euro.value + +result.cent.value * 0.01;
		this.customTip = selectedValue;
		return selectedValue;
	}

	recalculateAndEmitTip() {
		if (this.customTip) {
			this.tip.emit(this.customTip);
			return;
		}
		if (this.selectedPercent > 0) {
			this.tip.emit(
				+(this.totalPrice * this.selectedPercent * 0.01).toFixed(2)
			);
			return;
		}
		this.tip.emit(0);
		return;
	}

	select(percent: number) {
		if (this.selectedPercent === percent) {
			this.selectedPercent = 0;
		} else {
			this.selectedPercent = percent;
		}
	}
}
